
import bodytext from '@/mixins/bodytext'
export default {
  mixins: [bodytext],
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      tl: null,
    }
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
        // toggleActions: 'play reset play reverse',
        start: 'top center',
      },
    })
    this.tl.scrollTrigger.disable()

    if (this.$refs.body) {
      const splitWord = new this.$SplitText(this.$refs.body, { type: 'chars, words, lines' })
      if (this.$screen.lg) {
        this.tl.from(splitWord.words, { opacity: 0.3, color: '#42795C', translateX: 64, duration: 0.1, stagger: 0.1, ease: 'custom.1' }, '<')
      } else {
        this.tl.from(splitWord.chars, { opacity: 0.3, color: '#42795C', duration: 0.2, stagger: 0.005 }, '<')
      }
    }

    if (this.$refs.jumbotron) {
      // eslint-disable-next-line no-unused-vars
      const parentSplit = new this.$SplitText(this.$refs.jumbotron, {
        type: 'chars',
        charsClass: 'overflow-hidden block',
      })
      const childSplit = new this.$SplitText(this.$refs.jumbotron, { type: 'chars' })

      this.tl.from(
        childSplit.chars,
        {
          duration: 1,
          xPercent: 100,
          stagger: 0.05,
          ease: 'custom.3',
          onComplete: () => {
            childSplit.revert()
          },
        },
        '<+=0.3'
      )
    }
    this.$emit('sectionMounted')
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
  },
}
