export default {
  methods: {
    renderedBody(text) {
      if (text) {
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br>')
        text = text.replace(/(\*\*)(.*?)(\*\*)/g, '<span class="alt">$2</span>')
        return text
      }
      return ''
    },
  },
}
